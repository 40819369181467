import { useEffect, useState } from "react";
import "./App.css";
import {
  getMode,
  getTheme,
  getScreenSize,
  getActiveMenu,
  setActiveMenu,
  setScreenSize,
} from "./redux/theme/themeSlice";

import { useDispatch, useSelector } from "react-redux";
import Settings from "./components/settings/Settings";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Products from "./pages/products/Products";
import Footer from "./components/footer/Footer";
import LineChart from "./components/charts/LineChart";
import AreaChart from "./components/charts/AreaChart";
import BubbleChart from "./components/charts/BubbleChart";
import PieChart from "./components/charts/PieChart";
import BarStackedChart from "./components/charts/BarStackedChart";
import Editor from "./components/editor/Editor";
import Calendar from "./components/calendar/Calendar";
import OrderList from "./pages/orders/Orders";
import Post from "./pages/posts/Post";
import Gallery from "./pages/gallery/Gallery";
import { getUser, setUser } from "./redux/user/userSlice";
import Login from "./pages/login/Login";
import jwt_decode from "jwt-decode";
import PostList from "./pages/posts/PostList";
import { Toaster } from 'react-hot-toast';
import Slider from "./pages/slider/Slider";
import SliderList from "./pages/slider/SliderList";
import GalleryList from "./pages/gallery/GalleryList";
import AboutList from "./pages/abouts/AboutList";
import About from "./pages/abouts/About";

function App() {
  const theme = useSelector(getTheme);
  const mode = useSelector(getMode);
  const dispatch = useDispatch();
  const screenSize = useSelector(getScreenSize);
  const activeMenu = useSelector(getActiveMenu);
  const [localStorageUser, setLocalStorageData] = useState('')

  const user = useSelector(getUser);
  const [expInSeconds, setExpInSeconds] = useState(null);

  useEffect(() => {
    setLocalStorageData(localStorage.getItem('token'))
  }, [user])
  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenSize(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (screenSize <= 900) {
      dispatch(setActiveMenu(0));
    } else {
      dispatch(setActiveMenu(1));
    }
  }, [screenSize]);

  // useEffect(() => {
  //   // Check the expiration time of the JWT when the component is mounted
  //   const token = localStorage.getItem('token');
  //   const { exp } = token && token.length > 0 ? JSON.parse(atob(token.split('.')[1])) : '';
  //   setExpInSeconds(exp - Math.floor(Date.now() / 1000));
  // }, []);

  useEffect(() => {
    // Set up an interval to check the expiration time of the JWT every minute
    const intervalId = setInterval(() => {
      const token = localStorage.getItem('token');
      const { exp } = token && token.length > 0 ? JSON.parse(atob(token.split('.')[1])) : '';
      setExpInSeconds(exp - Math.floor(Date.now() / 1000));
    }, expInSeconds);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [expInSeconds]);

  useEffect(() => {
    // If the current time is greater than the expiration time of the JWT, log out the user
    if (expInSeconds <= 0) {
      dispatch(setUser(null));
      localStorage.removeItem('token');
    }
  }, [expInSeconds, dispatch]);
  
  return (
    <BrowserRouter>
      <div
        className={
          theme === "purple"
            ? "theme-purple " + mode
            : theme === "pink"
            ? "theme-pink " + mode
            : theme === "yellow"
            ? "theme-yellow " + mode
            : theme === "green"
            ? "theme-green " + mode
            : ""
        }
      >

{
  !user && !localStorageUser ? <Login /> : <><Settings />
        {activeMenu ? <Sidebar /> : null}
        <div className={` w-full ${activeMenu ? "custom:pl-64" : "flex-2"}`}>
          <div className="navbar w-full">
            <Toaster />
            <Navbar />
          </div>
          <div className="wrapper pt-[90px] p-4 dark:bg-slate-700 min-h-screen dark:text-slate-100">
            <Routes>
              {/* Dashboard */}
              <Route path="/" element={<Dashboard />} exact />
              {/* pages */}
              {/* <Route path="/products" element={<Products />} exact />
              <Route path="/orders" element={<OrderList />} exact /> */}
              <Route path="/posts" element={<PostList />} exact />
              <Route path="/add-post/:id" element={<Post />} exact />
              <Route path="/add-slider/:id" element={<Slider />} exact />
              <Route path="/sliders" element={<SliderList />} exact />
              <Route path="/add-gallery/:id" element={<Gallery />} exact />
              <Route path="/galleries" element={<GalleryList />} exact />
              <Route path="/about" element={<AboutList />} exact />
              <Route path="/add-about/:id" element={<About />} exact />


              {/* Apps */}
              {/* <Route path="/calendar" element={<Calendar />} />
              <Route path="/editor" element={<Editor />} /> */}
              {/* Charts */}
              {/* <Route path="/line-chart" element={<LineChart />} />
              <Route path="/area-chart" element={<AreaChart />} />
              <Route path="/bubble-chart" element={<BubbleChart />} />
              <Route path="/pie-chart" element={<PieChart />} />
              <Route path="/stacked-chart" element={<BarStackedChart />} /> */}
            </Routes>
          </div>
          <div>
            <Footer />
          </div>
        </div></>
}

        
      </div>
    </BrowserRouter>
  );
}

export default App;
