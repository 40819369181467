import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from '../../Util/apiCall';

export const saveGallery = createAsyncThunk(
    "gallery/saveGallery",
    async (obj) => {
       
        if (obj.id === 0) {
            const response = await apiCall.post("api/gallery-db/save", {
                album_title: obj.album_title,
                album_description: obj.album_description,
                location: obj.location,
                year: obj.year,
                // img: "image.jpg",
                attachments:obj.Attachments,
                tags: obj.tags
            });
    
        } else {
            const response = await apiCall.post("api/post-db/edit", {
                album_title: obj.album_title,
                album_description: obj.album_description,
                location: obj.location,
                year: obj.year,
                // img: "image.jpg",
                attachments:obj.Attachments,
                tags: obj.tags
            });
    
        }
    
    }
)

const gallerySlice = createSlice({
    name: 'gallery',
    initialState: {
        gallery: {
            album_title: "",
            album_description: "",
            location: "",
            year: "",
            Attachments:[],
            tags:[]
        },
        galleryList: [],
        success: false,
        error: null
    },
    reducers: {
        setGallery: (state, {payload}) => {
            state.gallery = {
                id:0,
                album_title: "",
                album_description: "",
                location: "",
                year: "",
                Attachments:[],
                tags:[]
            }
        }
    },
    extraReducers: {
        [saveGallery.pending]: (state, {payload}) => {
            state.success = false;
            state.error = null;
        },
        [saveGallery.rejected]: (state, {payload}) => {
            state.success = false;
            state.error = null;
        },
        [saveGallery.fulfilled]: (state, {payload}) => {
            state.success = true;
            state.error = payload;
        },
    }
});
export const {setGallery} = gallerySlice.actions;
export const getGallery = (state) => state.gallery.gallery;

export default gallerySlice.reducer;