import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
import apiCall from "../../Util/apiCall";


export const userLogin = createAsyncThunk(
    "user/userLogin",
    async (obj) => {
      const response =  await apiCall.post("/api/auth/signin", {
        username: obj.userName,
        password: obj.password,
    })
      const data = await response.data;

      setUser({userName: obj.userName, password: obj.password});
  
      localStorage.setItem('token', data.access_token)

      return obj;
    }
  );

const userSlice = createSlice({
    name:'user',
    initialState:{
        user:null,
        
    },
    reducers:{ 
        setUser: (state, {payload}) => {
            state.user = payload
        }
    },
    extraReducers:{
        [userLogin.pending]: () => {
            console.log("Fetching user Data...");
          },
          [userLogin.rejected]: () => {
            console.log("Could not fetch data...");
          },
          [userLogin.fulfilled]: (state, { payload }) => {
            return { ...state, user: payload };
          },
    }
})
export const {setUser} = userSlice.actions;
export const getUser = (state) => state.user.user;

export default userSlice.reducer;