import React, { useRef, useState } from "react";
import JoditEditor from "jodit-react";

const Editor = ({content, label, setContent}) => {
  const editor = useRef(null);
  // const [content, setContent] = useState("");

  return (
    <div className="p-8  w-full bg-skin-base rounded-lg flex flex-col gap-4 items-start justify-start dark:bg-slate-600">
      <label className="text-xl font-semibold dark:text-slate-200">
        {label}
      </label>
      <div className="w-full ">
        <JoditEditor
          ref={editor}
          value={content}
          tabIndex={1}
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => {setContent(newContent)}}
        />
      </div>
    </div>
  );
};

export default Editor;



// import React, { useState } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// const Editor = ({content, label, setContent}) => {
//   // const [content, setValue] = useState('');

//   return <ReactQuill theme="snow" value={content} onChange={setContent} />;
// }

// export default Editor;


// import React, { useRef } from "react";
// import JoditEditor from "jodit-react";

// const Editor = ({ content, label, setContent }) => {
//   const editor = useRef(null);

//   // Ensure content is a string or use an empty string if it's falsy
//   const initialContent = typeof content === "string" ? content : "";

//   return (
//     <div className="p-8 w-full bg-skin-base rounded-lg flex flex-col gap-4 items-start justify-start dark:bg-slate-600">
//       <label className="text-xl font-semibold dark:text-slate-200">
//         {label}
//       </label>
//       <div className="w-full">
//         <JoditEditor
//           ref={editor}
//           defaultValue={content}
//           tabIndex={1}
//           onBlur={(newContent) => setContent(newContent)}
//           onChange={(newContent) => {
//             setContent(newContent);
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default Editor;
