import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getActiveMenu,
  getScreenSize,
  setActiveMenu,
} from "../../redux/theme/themeSlice";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ListIcon from "@mui/icons-material/List";
import Icon from "@mui/material/Icon";
import { AddCircle } from "@mui/icons-material";
const Sidebar = () => {
  const dispatch = useDispatch();
  const screenSize = useSelector(getScreenSize);
  const activeMenu = useSelector(getActiveMenu);
  const [activeLink, setActiveLink] = useState("");

  const menuList = [
    // {
    //   label: "Dashboard",
    //   links: [
    //     { path: "admin-panel", name: "ecommerce", icon: "add_shopping_cart" },
    //   ],
    // },
    {
      label: "Pages",
      links: [
        // { path: "", name: "products", icon: "list" },
        { path: "add-post/new", name: "add-post", icon: <PostAddIcon /> },
        // { path: "add-gallery", name: "add-gallery", icon: "add" },
        { path: "posts", name: "posts-list", icon: <ListIcon /> },
        { path: "add-slider/new", name: "add-slider", icon: <AddCircle /> },
        { path: "sliders", name: "slider-list", icon: <ListIcon /> },
        { path: "about", name: "about-list", icon: <ListIcon /> },

        // { path: "add-gallery/new", name: "add-gallery", icon: <AddToPhotosIcon /> },
        // { path: "galleries", name: "gallery-list", icon: <PhotoLibraryIcon /> },

        // { path: "add-blog/new", name: "add-blog", icon: "add" },
        // { path: "", name: "add-order", icon: "add" },
        // { path: "", name: "customers", icon: "group_add" },
        // { path: "", name: "employees", icon: "groups" },
      ],
    },
    // {
    //   label: "Apps",
    //   links: [
    //     { path: "calendar", name: "calendar", icon: "calendar_month" },
    //     { path: "editor", name: "editor", icon: "edit" },
    //   ],
    // },
    // {
    //   label: "Chars",
    //   links: [
    //     { path: "line-chart", name: "line", icon: "show_chart_rounded" },
    //     { path: "area-chart", name: "area", icon: "analytics_rounded" },
    //     { path: "bubble-chart", name: "bubble", icon: "bubble_chart" },
    //     { path: "pie-chart", name: "pie", icon: "pie_chart_outlined" },
    //     {
    //       path: "stacked-chart",
    //       name: "stacked",
    //       icon: "stacked_bar_chart_rounded",
    //     },
    //   ],
    // },
  ];

  const handleCloseSidebar = (link) => {
    if (activeMenu && screenSize <= 900) {
      dispatch(setActiveMenu(!activeMenu));
      setActiveLink(link);
    }
  };

  return (
    <div
      className={` sidebar w-64 fixed  h-screen shadow-lg dark:shadow-md dark:shadow-slate-400 z-[99999]`}
    >
      <div className="h-[70px] text-slate-100 bg-skin-btn flex items-center justify-between px-2 ">
        <h2 className="text-2xl font-bold">Abdullah Al Muyeed</h2>
        {activeMenu && screenSize <= 900 ? (
          <div
            onClick={() => handleCloseSidebar()}
            className="bg-white px-2 py-1 text-skin-iconText rounded-[50%] hover:cursor-pointer hover:bg-slate-100"
          >
            <ArrowBack sx={{ height: "16px", width: "16px" }} />
          </div>
        ) : null}
      </div>
      <div className="h-screen bg-skin-base overflow-y-auto dark:bg-slate-700 dark:text-slate-100">
        <ul className="p-2 flex flex-col gap-1 pb-24">
          {menuList.map((item, index) => (
            <>
              <label
                key={index}
                className="text-slate-500 dark:text-slate-400 uppercase"
              >
                {item.label}
              </label>
              {item.links.map((sitem, sindex) => (
                <Link to={sitem.path} key={sindex}>
                  <li
                    onClick={() => handleCloseSidebar()}
                    className={`flex items-center gap-3 p-3 text-sm rounded-lg hover:cursor-pointer hover:bg-skin-btn hover:text-skin-btnText`}
                  >
                    <Icon>{sitem.icon}</Icon>{" "}
                    <span className="capitalize">{sitem.name}</span>
                  </li>
                </Link>
              ))}
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
