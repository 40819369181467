import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from '../../Util/apiCall';

export const savePost = createAsyncThunk(
    "post/savePosts",
    async (obj) => {

        if (obj.id === 0) {
            const response = await apiCall.post("api/post-db/save", {
                title: obj.title,
                content: obj.content,
                post_type: obj.post_type,
                // img: "image.jpg",
                publish_date: obj.publish_date,
                end_date: obj.end_date,
                url: obj.url,
                author: obj.author,
                attachments:obj.Attachments,
                tags: obj.tags
            });
    
        } else {
            const response = await apiCall.post("api/post-db/edit", {
                title: obj.title,
                content: obj.content,
                post_type: obj.post_type,
                // img: "image.jpg",
                publish_date: obj.publish_date,
                end_date: obj.end_date,
                url: obj.url,
                author: obj.author,
                attachments:obj.Attachments,
                tags: obj.tags,
                id: obj.id
            });
    
        }
    
        //return response;
    }
)
export const getPosts = createAsyncThunk(
    "post/getPosts",
    async () => {
        const response = await apiCall.get("/api/post-db/posts");
        const data = await response.data;

        // const updateData =  data && data.length > 0 && data.map(x=> {
        //     return {
        //         ...x,
        //         comment_count: x.comment_count ? x.comment_count : 0,
        //         tags:x.tags ? x.tags : '',
        //         tag_name:x.tag_name ? x.tag_name : ''
        //     }
        // })
        return data.data;
    }
)
export const getPostById = createAsyncThunk(
    "post/getPostById",
    async (id) => {
        const response = await apiCall.get(`/api/post-db/postById/${id}`);
        const data = await response.data.data;
        const model = {
            ...data,
            Attachments: data.images
        }
  
        return model;
    }
)

export const deletePostById = createAsyncThunk(
    "post/deletePostById",
    async (id) => {
        const response = await apiCall.delete(`/api/post-db/postById/${id}`);
        const data = await response.data.data;
        return data;
    }
)


const postSlice = createSlice({
    name: 'post',
    initialState: {
        post: {
            title:"",
            content:"",
            post_type:"",
            publish_date:null,
            end_date:null,
            url:"",
            author: "",
            Attachments:[],
            tags:[],
            id:0
        },
        postList: [],
        success: false,
        error: null
    },
    reducers: {
        setPost: (state, {payload}) => {
            state.post = {
                id:0,
                title:"",
                content:"",
                post_type:"",
                publish_date:null,
                end_date:null,
                url:"",
                author: "Abdullah Al-Muyeed",
                Attachments:[],
                tags:[]
            }
        }
    },
    extraReducers: {
        [getPosts.pending]: () => {
            console.log("Fetching post Data...");
        },
        [getPosts.rejected]: () => {
            console.log("Could not fetch data...");
        },
        [getPosts.fulfilled]: (state, { payload }) => {
            return { ...state, postList: payload };
        },

        [getPostById.pending]: () => {
            console.log("Fetching post Data...");
        },
        [getPostById.rejected]: () => {
            console.log("Could not fetch data...");
        },
        [getPostById.fulfilled]: (state, { payload }) => {
            return { ...state, post: payload };
        },
        [savePost.pending]: (state, {payload}) => {
            state.success = false;
            state.error = null;
        },
        [savePost.rejected]: (state, {payload}) => {
            state.success = false;
            state.error = null;
        },
        [savePost.fulfilled]: (state, {payload}) => {
            state.success = true;
            state.error = payload;
        },
        [deletePostById.pending]: (state, {payload}) => {
            state.success = false;
            state.error = null;
        },
        [deletePostById.rejected]: (state, {payload}) => {
            state.success = false;
            state.error = null;
        },
        [deletePostById.fulfilled]: (state, {payload}) => {
            state.success = true;
            state.error = payload;
        },
        
        
    }
});
export const {setPost} = postSlice.actions;
export const getPost = (state) => state.post.post;
export const getAllPosts = (state) => state.post.postList;

export default postSlice.reducer;