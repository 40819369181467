import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../helper";
import {
  getSlider,
  saveSlider,
  getSliderById,
  setSlider,
} from "../../redux/slider/sliderSlice";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import _ from "../../helper/lodash";
import DropzoneFileUploader from "../../helper/DropzoneFileUploader";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import baseUrl from "../../Util/baseURL";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const Slider = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();
  const slider = useSelector(getSlider);

  const [tags, setTags] = useState([]);

  const { form, handleChange, setForm } = useForm(slider);

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [viewDbPreviewImg, setViewDbPreviewImg] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (id === "new") {
      dispatch(setSlider());
    } else {
      dispatch(getSliderById(id));
    }
  }, [dispatch]);

  //   useEffect(() => {
  //     if (slider != form) {
  //       setForm(slider);
  //       let tagsList = [];
  //       slider.tags.map((x) => {
  //         tagsList.push({ id: x.name, text: x.name });
  //       });
  //       setTags(tagsList || []);
  //     }
  //   }, [slider]);

  useEffect(() => {
    if (slider !== form) {
      setForm(form);
    }
  }, [form, handleChange, setForm]);

  useEffect(() => {
    setForm(slider);
  }, [slider]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const upForm = {
      ...form,
    };

    dispatch(saveSlider(upForm)).then((res) => {
      if (id != "new" && !res.error) {
        navigate("/sliders");
      }
      if (res.error) {
        dispatch(setSlider());
        toast.error("Error saving slider " + res.error.message);
      } else {
        dispatch(setSlider());
        navigate("/sliders");
        toast.success("Slider saved successfully");
      }
    });
  };

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    setUploadedFile(files[0]);
    setViewDbPreviewImg(false);
    let tmpAtc =
      form && form.Attachments && form.Attachments.length > 0
        ? [...form.Attachments]
        : [];

    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin
        if (tmpAtc.length > 0) {
          tmpAtc[0] = {
            AID: Math.random(),
            img: `data:${files[index].type};base64,${btoa(bin)}`,
            type: files[index].type,
            filename: files[index].name,
          };
        } else {
          tmpAtc.push({
            AID: Math.random(),
            img: `data:${files[index].type};base64,${btoa(bin)}`,
            type: files[index].type,
            filename: files[index].name,
          });
        }

        setForm(_.set({ ...form }, `Attachments`, tmpAtc));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <TextField
          required
          id="outlined-required"
          value={form.title}
          onChange={handleChange}
          name="title"
          label="Title"
          error={form.title == ""}
          className="flex-1 w-full"
        />
        <TextField
          required
          id="outlined-required"
          value={form.sub_title}
          onChange={handleChange}
          name="sub_title"
          label="Subtitle"
          error={form.sub_title == ""}
          className="flex-1 w-full"
        />
      </div>
      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <TextField
          id="outlined-required"
          label="Slider Text"
          value={form.slider_text}
          name="slider_text"
          error={form.slider_text == ""}
          onChange={handleChange}
          className="flex-1  w-full"
        />

        <TextField
          id="outlined-required"
          label="Sequence No"
          type="number"
          InputProps={{
            inputProps: {
              max: 1000,
              min: 1,
            },
          }}
          value={form.sequence_no}
          name="sequence_no"
          error={form.sequence_no == ""}
          onChange={handleChange}
          className="flex-1  w-full"
        />
      </div>
      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <div className="flex items-center gap-4 flex-col">
          <DropzoneFileUploader
            acceptedFileTypes={["image/jpg", "image/jpeg", "image/png"]}
            handleAddAttachment={handleAddAttachment}
          />

          <div className="py-8">
            {form &&
              form.Attachments &&
              form.Attachments.length > 0 &&
              form.Attachments.map((file) => (
                <div className="flex flex-1 items-center">
                  <p>{file.filename}</p>
                </div>
              ))}
          </div>
          {id === "new" ? (
            <div>
              {form &&
                form.Attachments &&
                form.Attachments.length > 0 &&
                form.Attachments.map((file) => (
                  <img
                    src={
                      file.AID
                        ? URL.createObjectURL(uploadedFile)
                        : baseUrl.baseUrl + file.upload_path
                    }
                    crossOrigin="anonymous"
                    title="Attachemnt"
                    className="w-[300px] h-[200px] rounded-xl"
                  />
                ))}
            </div>
          ) : (
            <div>
              {viewDbPreviewImg ? (
                <img
                  crossOrigin="anonymous"
                  src={
                    form &&
                    form.img &&
                    baseUrl.baseUrl +
                      form.img.split("/webapi.aamuyeed.com").pop()
                  }
                  alt="Image"
                  className="w-[300px] h-[200px] rounded-xl"
                />
              ) : (
                <div>
                  {form &&
                    form.Attachments &&
                    form.Attachments.length > 0 &&
                    form.Attachments.map((file) => (
                      <img
                        src={
                          file.AID
                            ? URL.createObjectURL(uploadedFile)
                            : baseUrl.baseUrl + file.upload_path
                        }
                        crossOrigin="anonymous"
                        title="Attachemnt"
                        className="w-[300px] h-[200px] rounded-xl"
                      />
                    ))}
                </div>
              )}
            </div>
          )}
        </div>

        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit}
          endIcon={<SaveIcon />}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Slider;
