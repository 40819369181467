import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Table, TableCell, TableHead, TableBody, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllPosts, getPosts, deletePostById } from '../../redux/post/postSlice';
import DOMPurify from 'dompurify';
import toast from "react-hot-toast";
import ReactPaginate from 'react-paginate';
import SearchIcon from "@mui/icons-material/Search";

const GalleryList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const postList = useSelector(getAllPosts);

    useEffect(() => {
        dispatch(getPosts())
    }, []);

    const handleEdit = (e, id) => {
        e.preventDefault();
        navigate(`/add-post/${id}`, {state: {id:id}})
    }
    const handleDelete = (e, id) => {
        e.preventDefault();
        dispatch(deletePostById(id)).then(res => {
              if (res.error) {
                toast.error("Error saving post " + res.error.message);
              } else {
                dispatch(getPosts());
                toast.success("Post saved successfully!");
              }
        });
    }
    const [searchText, setSearchText] = useState('')

    const [itemsPerPage, setItemsPerPage] = useState(10)
        const [itemOffset, setItemOffset] = useState(0);
        const [currentItems , setCurrentItems]= useState([]);
        const [pageCount, setPageCount] = useState(0)

      
        useEffect(() => {
          const endOffset = itemOffset + itemsPerPage;
          setCurrentItems(postList && postList.length > 0 && postList.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(postList.length / itemsPerPage));
        
        }, [postList,])


          // Invoke when user click to request another page.
          const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % postList.length;
            const endOffset = newOffset + itemsPerPage;

            setCurrentItems(postList && postList.length > 0 && postList.slice(newOffset, endOffset));

            setItemOffset(newOffset);
          };
        const handleSearch = e=> {
            setSearchText(e.target.value);

            // const temp  = postList.filter((item) => {
            //     return Object.keys(item).some(key => item[key].toString().search(e.target.value) !== -1);
            // });
            // const temp = postList.filter((item) => {
            //     if (!e.target || !e.target.value) {
            //       return false;
            //     }
              
            //     return Object.keys(item).some(key => item[key].toString().search(e.target.value) !== -1);
            // });
            const temp = postList.filter((item) => {
              if (!e.target || !e.target.value) {
                // dispatch(getPosts());
                setCurrentItems(postList);
                // return false;
              }
            
              return item.title.toLowerCase().toString().search(e.target.value.toLowerCase()) !== -1 || 
              item.post_type.toLowerCase().toString().search(e.target.value.toLowerCase()) !== -1;
            });
            setCurrentItems(temp);
        }
  return (
    <div>
        <div>
        <div className="hidden md:flex bg-white w-full rounded-md flex items-center justify-start py-2 px-2 shadow-md">
          <div className=" text-skin-iconText">
            <SearchIcon />
          </div>
          <div className="w-full">
            <input
              className="w-full px-2 py-1 text-slate-700 outline-none border-none"
              value={searchText}
              onChange={e=>handleSearch(e)}
              placeholder="Search..."
            />
          </div>
        </div>
        </div>

            <table style={{width: '100%', marginTop:'15px'}}>
            
                <thead>
                    <tr>
                        <th style={{border:'1px solid lightgray'}}>Title</th>
                        <th style={{border:'1px solid lightgray'}}>Content</th>
                        <th style={{border:'1px solid lightgray'}}>Type</th>
                        <th style={{border:'1px solid lightgray'}}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                {currentItems && currentItems.length > 0 && currentItems.map((x, i)=> (
                    <tr key={i}>
                        <td  style={{width: '200px', padding:'5px', border:'1px solid lightgray'}}>{x.title}</td>
                        {x.post_type === 'blog' ? <td><p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(x.content.substring(0, x.content.indexOf("</p>") + 4))}}></p></td> :
                         <td style={{width: '400px', padding:'5px', border:'1px solid lightgray'}}><a href={x.url} target="_blank" className='underline break-all'>{x.url}</a></td>}
                        
                        <td style={{width: '100px', padding:'5px', border:'1px solid lightgray', textAlign: 'center'}}>{x.post_type}</td>
                        <td style={{width: '100px', padding:'5px', border:'1px solid lightgray', textAlign: 'center'}}>
                            <IconButton color="primary" onClick={e => handleEdit(e, x.id)}><Edit /></IconButton>
                            <IconButton color="secondary" onClick={e => handleDelete(e, x.id)}><Delete /></IconButton>
                        </td>
                    </tr>
                
                ))}
                </tbody>
            </table>
            <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        className="w-full flex items-center gap-3 py-4 justify-end"
                        activeClassName="bg-purple-100 text-purple-800 rounded-full px-2 py-1 h-[30px] w-[30px] flex items-center justify-center"      
                        pageLinkClassName="border px-4 py-2 rounded-[50%] hover:bg-slate-200"
                    />
        
    </div>
  )
}

export default GalleryList;