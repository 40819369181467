import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "../../Util/apiCall";

export const saveAbout = createAsyncThunk("about/saveAbout", async (obj) => {
  if (obj.id === 0) {
    const response = await apiCall.post("api/about-db/save", {
      profile_header_title: obj.profile_header_title,
      profile_header_value: obj.profile_header_value,
      title_animation_value: obj.title_animation_value,
      title_animation_header: obj.title_animation_header,
      description: obj.content,
      additional_field_1: obj.content,
      additional_field_2: obj.content,
      additional_field_3: obj.content,
      additional_field_4: obj.content,
      additional_field_5: obj.content,
      attachments: obj.Attachments,
    });
  } else {
    console.log(obj);
    const response = await apiCall.post("api/about-db/edit", {
      profile_header_title: obj.profile_header_title,
      profile_header_value: obj.profile_header_value,
      title_animation_value: obj.title_animation_value,
      title_animation_header: obj.title_animation_header,
      description: obj.content,
      additional_field_1: obj.content,
      additional_field_2: obj.content,
      additional_field_3: obj.content,
      additional_field_4: obj.content,
      additional_field_5: obj.content,
      attachments: obj.Attachments,
      id: obj.id,
    });
  }
});
export const getAbout = createAsyncThunk("about/getAbout", async () => {
  const response = await apiCall.get("/api/about-db/about");
  const data = await response.data;
  return data.data;
});
export const getAboutById = createAsyncThunk(
  "about/getAboutById",
  async (id) => {
    const response = await apiCall.get(`/api/about-db/aboutById/${id}`);
    const data = await response.data.data;
    const model = {
      ...data,
      Attachments: data.images,
    };

    return model;
  }
);

// export const deleteAboutById = createAsyncThunk(
//     "about/deleteAboutById",
//     async (id) => {
//         const response = await apiCall.delete(`/api/about-db/postById/${id}`);
//         const data = await response.data.data;
//         return data;
//     }
// )

const aboutSlice = createSlice({
  name: "about",
  initialState: {
    about: {
      profile_header_title: "",
      profile_header_value: "",
      title_animation_value: "",
      title_animation_header: "",
      description: "",
      additional_field_1: "",
      additional_field_2: "",
      additional_field_3: "",
      additional_field_4: "",
      additional_field_5: "",
      Attachments: [],
      id: 0,
    },
    aboutList: [],
    success: false,
    error: null,
  },
  reducers: {
    setAbout: (state, { payload }) => {
      state.about = {
        profile_header_title: "",
        profile_header_value: "",
        title_animation_value: "",
        title_animation_header: "",
        description: "",
        additional_field_1: "",
        additional_field_2: "",
        additional_field_3: "",
        additional_field_4: "",
        additional_field_5: "",
        Attachments: [],
        id: 0,
      };
    },
  },
  extraReducers: {
    [getAbout.pending]: () => {
      console.log("Fetching about Data...");
    },
    [getAbout.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getAbout.fulfilled]: (state, { payload }) => {
      return { ...state, aboutList: payload };
    },

    [getAboutById.pending]: () => {
      console.log("Fetching about Data...");
    },
    [getAboutById.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getAboutById.fulfilled]: (state, { payload }) => {
      return { ...state, about: payload };
    },
    [saveAbout.pending]: (state, { payload }) => {
      state.success = false;
      state.error = null;
    },
    [saveAbout.rejected]: (state, { payload }) => {
      state.success = false;
      state.error = null;
    },
    [saveAbout.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.error = payload;
    },
    // [deleteAboutById.pending]: (state, {payload}) => {
    //     state.success = false;
    //     state.error = null;
    // },
    // [deleteAboutById.rejected]: (state, {payload}) => {
    //     state.success = false;
    //     state.error = null;
    // },
    // [deleteAboutById.fulfilled]: (state, {payload}) => {
    //     state.success = true;
    //     state.error = payload;
    // },
  },
});
export const { setAbout } = aboutSlice.actions;
export const getAboutC = (state) => state.about.about;
export const getAllAbout = (state) => state.about.aboutList;

export default aboutSlice.reducer;
