import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../helper";
import { getGallery, saveGallery, setGallery } from "../../redux/gallery/gallerySlice";
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Editor from "../../components/editor/Editor";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import _ from '../../helper/lodash'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DropzoneFileUploader from "../../helper/DropzoneFileUploader";
import { WithContext as ReactTags } from 'react-tag-input';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import baseUrl from "../../Util/baseURL";
import DOMPurify from "dompurify";

const KeyCodes = {
  comma: 188,
  enter: 13
};
const Gallery = () => {
  // const dispatch = useDispatch();
  // const gallery = useSelector(getGallery);
  // console.log(gallery)
  // const [tags, setTags] = useState([])

  // const {form, handleChange, setForm} = useForm(gallery);
  
  // const delimiters = [KeyCodes.comma, KeyCodes.enter];
  // useEffect(() => {
  //   if(gallery != form){
  //     setForm(form)
  //   }
  // }, [form, handleChange, setForm])

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   let tagsStr = "";
  //   tags.map(x=> {
  //     tagsStr += x.id+',';
  //   })
  //   if(tagsStr.length > 0) {
  //     tagsStr = tagsStr.substring(0, tagsStr.length-1)
  //   }
  //   const upForm = {
  //     ...form,
  //     tags:tagsStr
  //   }
  //   dispatch(saveGallery(upForm));
  // }
  // const setContent = content => {
  //   setForm(_.set({ ...form }, 'content', content));
  // }

  // const handleChangeDate =(newValue, name)=> {
  //   setForm(_.set({ ...form }, name, newValue));
  // }

	// const handleRemoveFile = id => {
	// 	let oldAttachments = JSON.parse(JSON.stringify(form.Attachments));
	// 	let newAttachments = oldAttachments.filter(el => el.AID != id);
	// 	setForm(_.set({ ...form }, `Attachments`, newAttachments));
	// };


	// const handleDescriptionChange = (id, event) => {
	// 	let attachments = JSON.parse(JSON.stringify(form.Attachments));
	// 	const updatedData = attachments.map(x => (x.AID === id ? { ...x, Description: event.target.value } : x));
	// 	setForm(_.set({ ...form }, `Attachments`, updatedData));
	// };
  // const handleDelete = i => {
  //   setTags(tags.filter((tag, index) => index !== i));
  // };

  // const handleAddition = tag => {
  //   setTags([...tags, tag]);
  // };
	// const handleAddAttachment = files => {
	// 	if (!files.length) {
	// 		return;
	// 	}
	// 	let tmpAtc = [...form.Attachments];
	// 	var reader = new FileReader();
	// 	function readFile(index) {
	// 		if (index >= files.length) return;
	// 		var file = files[index];
	// 		reader.onload = function (e) {
	// 			// get file content
	// 			var bin = e.target.result;
  //       tmpAtc.push({
  //         AID:Math.random(),
  //         img: `data:${files[index].type};base64,${btoa(bin)}`,
  //         type: files[index].type,
  //         filename: files[index].name,
  //       });
				
	// 			setForm(_.set({ ...form }, `Attachments`, tmpAtc));
	// 			readFile(index + 1);
	// 		};
	// 		reader.readAsBinaryString(file);
	// 	}
	// 	readFile(0);
	// };

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();
  const gallery = useSelector(getGallery);
  const [tags, setTags] = useState([]);

  const { form, handleChange, setForm } = useForm(gallery);
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [error, setError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null)

  useEffect(() => {
    if (id == "new") {
      dispatch(setGallery());
    } else {
      // dispatch(getPostById(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (gallery != form) {
      setForm(gallery);
      let tagsList = [];
      gallery.tags.map((x) => {
        tagsList.push({ id: x.name, text: x.name });
      });
      setTags(tagsList || []);
    }
  }, [gallery]);

  useEffect(() => {
    if (gallery != form) {
      setForm(form);
    }
  }, [form, handleChange, setForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let tagsStr = "";
    tags.map((x) => {
      tagsStr += x.id + ",";
    });
    if (tagsStr.length > 0) {
      tagsStr = tagsStr.substring(0, tagsStr.length - 1);
    }
    const upForm = {
      ...form,
      tags: tagsStr,
    };
    dispatch(saveGallery(upForm)).then((res) => {
      if(id != 'new' && !res.error) {
        navigate("/posts");
      } 
      if (res.error) {
        dispatch(setGallery());
        toast.error("Error saving gallery " + res.error.message);
      } else {
        dispatch(setGallery());
        toast.success("Gallery saved successfully");
      }
    });
  };

  const setContent = (content) => {
    setForm(
      _.set(
        { ...form },
        "album_description",
        DOMPurify.sanitize(content, { ALLOW_SCRIPTS: false })
      )
    );
  };

  const handleChangeDate = (newValue, name) => {
    setForm(_.set({ ...form }, name, newValue));
  };

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };
  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    setUploadedFile(files[0])
    let tmpAtc =
      form && form.Attachments && form.Attachments.length > 0
        ? [...form.Attachments]
        : [];
    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin
        tmpAtc.push({
          AID: Math.random(),
          img: `data:${files[index].type};base64,${btoa(bin)}`,
          type: files[index].type,
          filename: files[index].name,
        });

        setForm(_.set({ ...form }, `Attachments`, tmpAtc));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };
  return <div className="flex flex-col gap-4">
        <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <TextField
          required
          id="outlined-required"
          value={form.album_title}
          onChange={handleChange}
          name="album_title"
          label="Album Title"
          className="flex-1 w-full"
        />
        
        <TextField
          id="outlined-required"
          label="Location"
          value={form.location}
          name="location"
          onChange={handleChange}
          className="flex-1  w-full"
        />
        </div>
        
        <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <LocalizationProvider dateAdapter={AdapterMoment}>

            <MobileDatePicker
              label="Year"
              inputFormat="MM/DD/YYYY"
              value={form.year}
              onChange={value=>handleChangeDate(value, "year")}
              renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
        
        </div>
        <ReactTags
            inline
            tags={tags}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            inputFieldPosition="bottom"
            autocomplete
            className="w-full"
          />
        <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
          <Editor content={form.album_description} setContent={setContent}/>
        </div>
        

    {/* <input value={form.ProductCode} onChange={handleChange} name="ProductCode" placeholder="Product Code"/>
    <input value={form.ProductCategory} onChange={handleChange} name="ProductCategory" placeholder="Product Category"/> */}
    <div className="flex items-center gap-4 flex-col">
    
    <DropzoneFileUploader handleAddAttachment={handleAddAttachment} />

    <div className="py-8">
									{form && form.Attachments &&
										form.Attachments.length > 0 &&
										form.Attachments.map(file => (
											<div className="flex flex-1 items-center">
											<p>{file.filename}</p>
                      </div>
										))}
								</div>


    <Button variant="contained" color="success" onClick={handleSubmit} endIcon={<SaveIcon />}>
        Save
    </Button>
    </div>
  </div>;
};


export default Gallery;
