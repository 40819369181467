import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from '../../Util/apiCall';

export const saveSlider = createAsyncThunk(
    "slider/saveSlider",
    async (obj) => {
        if (obj.id === 0) {

            const response = await apiCall.post("api/sliders", {
                title: obj.title,
                sub_title: obj.sub_title,
                slider_text: obj.slider_text,
                sequence_no: obj.sequence_no,
                Attachments:obj.Attachments,
            });
    
        } else {
            const response = await apiCall.put("api/sliders/edit", {
                title: obj.title,
                sub_title: obj.sub_title,
                sequence_no: obj.sequence_no,
                slider_text: obj.slider_text,
                Attachments:obj.Attachments,
                id: obj.id
            });
    
        }
    }
)
export const getSliders = createAsyncThunk(
    "slider/getSliders",
    async () => {
        const response = await apiCall.get("/api/sliders");
        const data = await response.data;
        return data.data;
    }
)
export const getSliderById = createAsyncThunk(
    "slider/getSliderById",
    async (id) => {
        const response = await apiCall.get(`/api/sliders/${id}`);
        const data = await response.data.data;
        const model = {
            ...data,
            Attachments: data.images
        }
  
        return model;
    }
)

export const deleteSliderById = createAsyncThunk(
    "slider/deleteSliderById",
    async (id) => {
        const response = await apiCall.delete(`/api/sliders/${id}`);
        const data = await response.data;
        return data;
    }
)

const sliderSlice = createSlice({
    name: 'slider',
    initialState: {
        slider: {
            title: "",
            sub_title:"",
            slider_text:"",
            sequence_no:"",
            image:null,
            Attachments:[],
            id:0
        },
        sliderList: [],
        success: false,
        error: null
    },
    reducers: {
        setSlider: (state, {payload}) => {
            state.slider = {
                title: "",
                id:0,
                sub_title:"",
                slider_text:"",
                sequence_no:"",
                Attachments:[],
                image:null,
            }
        }
    },
    extraReducers: {
        [getSliders.pending]: () => {
            console.log("Fetching slider Data...");
        },
        [getSliders.rejected]: () => {
            console.log("Could not fetch data...");
        },
        [getSliders.fulfilled]: (state, { payload }) => {
            return { ...state, sliderList: payload };
        },
        [getSliderById.pending]: () => {
            console.log("Fetching slider Data...");
        },
        [getSliderById.rejected]: () => {
            console.log("Could not fetch data...");
        },
        [getSliderById.fulfilled]: (state, { payload }) => {
            return { ...state, slider: payload };
        },
        [saveSlider.pending]: (state, {payload}) => {
            state.success = false;
            state.error = null;
        },
        [saveSlider.rejected]: (state, {payload}) => {
            state.success = false;
            state.error = null;
        },
        [saveSlider.fulfilled]: (state, {payload}) => {
            state.success = true;
            state.error = payload;
        }
    }
});
export const {setSlider} = sliderSlice.actions;
export const getSlider = (state) => state.slider.slider;
export const getAllSliders = (state) => state.slider.sliderList;

export default sliderSlice.reducer;