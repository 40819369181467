import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'


export const saveProducts = createAsyncThunk(
    "product/saveProducts",
    async (obj) => {
      const response =  await axios.get("/api/timelinedb-app/timelineData", obj);
      const data = await response.data.data;
  
      return data;
    }
  );

const productSlice = createSlice({
    name:'product',
    initialState:{
        product:{},
        productList: [],
        newProduct:{
            ProductID: 1,
            ProductName:'asf saf asf',
            ProductCode: 'a f54yt 54yf dfasf',
            ProductCategory: 'a sfasf y6g'
       }
    },
    reducers:{ 
        setProduct: (state, {payload}) => {
            state.product = payload
        }
    },
    extraReducers:{
        [saveProducts.pending]: () => {
            console.log("Fetching procuct Data...");
          },
          [saveProducts.rejected]: () => {
            console.log("Could not fetch data...");
          },
          [saveProducts.fulfilled]: (state, { payload }) => {
            return { ...state, productList: payload };
          },
    }
})
export const {setProduct} = productSlice.actions;
export const getNewProduct = (state) => state.product.newProduct;
export const getAllProducts = (state) => state.product.productList;

export default productSlice.reducer;