import { configureStore } from "@reduxjs/toolkit";
import themeSlice from "./theme/themeSlice";
import productSlice from "./product/productSlice";
import postSlice from "./post/postSlice";
import gallerySlice from "./gallery/gallerySlice";
import userSlice from "./user/userSlice";
import sliderSlice from "./slider/sliderSlice";
import aboutSlice from "./about/aboutSlice";

const store = configureStore({
  reducer: {
    theme: themeSlice,
    product: productSlice,
    post: postSlice,
    gallery: gallerySlice,
    slider: sliderSlice,
    user: userSlice,
    about: aboutSlice,
  },
});
export default store;
