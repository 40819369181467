import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../helper";
import {
  getPost,
  getPostById,
  savePost,
  setPost,
} from "../../redux/post/postSlice";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Editor from "../../components/editor/Editor";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "../../helper/lodash";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DropzoneFileUploader from "../../helper/DropzoneFileUploader";
import { WithContext as ReactTags } from "react-tag-input";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import baseUrl from "../../Util/baseURL";
import DOMPurify from "dompurify";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const Post = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();
  const post = useSelector(getPost);

  const [tags, setTags] = useState([]);

  const { form, handleChange, setForm } = useForm(post);
  const names = [
    "blog",
    "news",
    "events",
    "books",
    "publications",
    "policy-advocacy",
  ];
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [error, setError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (id == "new") {
      dispatch(setPost());
    } else {
      dispatch(getPostById(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (post != form) {
      setForm(post);
      let tagsList = [];
      post.tags.map((x) => {
        tagsList.push({ id: x.name, text: x.name });
      });
      setTags(tagsList || []);
    }
  }, [post]);

  useEffect(() => {
    if (post != form) {
      setForm(form);
    }
  }, [form, handleChange, setForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let tagsStr = "";
    tags.map((x) => {
      tagsStr += x.id + ",";
    });
    if (tagsStr.length > 0) {
      tagsStr = tagsStr.substring(0, tagsStr.length - 1);
    }
    const upForm = {
      ...form,
      tags: tagsStr,
    };
    dispatch(savePost(upForm)).then((res) => {
      if (id != "new" && !res.error) {
        navigate("/posts");
      }
      if (res.error) {
        dispatch(setPost());
        toast.error("Error saving post " + res.error.message);
      } else {
        dispatch(setPost());
        navigate("/posts");
        toast.success("Post saved successfully");
      }
    });
  };

  // useEffect(() => {
  //   if (success) {
  //     console.log("From suc")
  //     toast.success('Post saved successfully!');
  //   } else if (error) {
  //     console.log("From error")

  //     toast.error('Error saving post');
  //   }
  // }, [success, error]);

  const setContent = (content) => {
    setForm(
      _.set(
        { ...form },
        "content",
        DOMPurify.sanitize(content, { ALLOW_SCRIPTS: false })
      )
    );
  };

  const handleChangeDate = (newValue, name) => {
    setForm(_.set({ ...form }, name, newValue));
  };
  const handleChangeType = (e) => {
    // if (!e.target.value) {
    //   setError(true);
    // }
    setForm(_.set({ ...form }, "post_type", e.target.value));
  };

  const handleRemoveFile = (id) => {
    let oldAttachments = JSON.parse(JSON.stringify(form.Attachments));
    let newAttachments = oldAttachments.filter((el) => el.AID != id);
    setForm(_.set({ ...form }, `Attachments`, newAttachments));
  };

  const handleDescriptionChange = (id, event) => {
    let attachments = JSON.parse(JSON.stringify(form.Attachments));
    const updatedData = attachments.map((x) =>
      x.AID === id ? { ...x, Description: event.target.value } : x
    );
    setForm(_.set({ ...form }, `Attachments`, updatedData));
  };
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };
  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    setUploadedFile(files[0]);
    let tmpAtc =
      form && form.Attachments && form.Attachments.length > 0
        ? [...form.Attachments]
        : [];
    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin
        if (tmpAtc.length > 0) {
          tmpAtc[0] = {
            AID: Math.random(),
            img: `data:${files[index].type};base64,${btoa(bin)}`,
            type: files[index].type,
            filename: files[index].name,
          };
        } else {
          tmpAtc.push({
            AID: Math.random(),
            img: `data:${files[index].type};base64,${btoa(bin)}`,
            type: files[index].type,
            filename: files[index].name,
          });
        }

        setForm(_.set({ ...form }, `Attachments`, tmpAtc));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <FormControl
          className="flex-1 w-full"
          required
          error={form.post_type == ""}
        >
          <InputLabel id="demo-simple-select-helper-label">
            Post Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            className=" w-full"
            label="Post Type"
            name="post_type"
            value={form.post_type}
            onChange={handleChangeType}
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          id="outlined-required"
          value={form.title}
          onChange={handleChange}
          name="title"
          label="Title"
          error={form.title == ""}
          className="flex-1 w-full"
        />
        {form.post_type == "blog" ? (
          ""
        ) : (
          <TextField
            id="outlined-required"
            label="Url"
            value={form.url}
            name="url"
            error={form.url == ""}
            onChange={handleChange}
            className="flex-1  w-full"
          />
        )}
      </div>
      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        {form.post_type == "blog" ? (
          <TextField
            id="outlined-required"
            label="Author"
            value={form.author || ""}
            name="author"
            onChange={handleChange}
            className="flex-1 w-full"
          />
        ) : (
          ""
        )}

        {form.post_type == "events" ? (
          <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                className="flex-1 w-full"
                label="Publish Date"
                value={form.publish_date}
                name="publish_date"
                onChange={(value) => handleChangeDate(value, "publish_date")}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                className="flex-1 w-full"
                label="End Date"
                value={form.end_date}
                name="end_date"
                onChange={(value) => handleChangeDate(value, "end_date")}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </>
        ) : (
          ""
        )}

        {form.post_type == "publications" ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              className="flex-1 w-full"
              label="Publish Date"
              value={form.publish_date}
              name="publish_date"
              onChange={(value) => handleChangeDate(value, "publish_date")}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        ) : (
          ""
        )}
      </div>

      {form.post_type == "blog" ? (
        <>
          <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
            <Editor content={form.content} setContent={setContent} />
          </div>
          <ReactTags
            inline
            tags={tags}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            inputFieldPosition="bottom"
            autocomplete
            className="w-full border"
          />
        </>
      ) : (
        ""
      )}

      <div className="flex items-center gap-4 flex-col">
        <DropzoneFileUploader
          acceptedFileTypes={["image/jpg", "image/jpeg", "image/png"]}
          handleAddAttachment={handleAddAttachment}
        />

        <div className="py-8">
          {form &&
            form.Attachments &&
            form.Attachments.length > 0 &&
            form.Attachments.map((file) => (
              <div className="flex flex-1 items-center">
                <p>{file.filename}</p>
              </div>
            ))}
        </div>
        {id != "new" ? (
          <div>
            {form &&
              form.Attachments &&
              form.Attachments.length > 0 &&
              form.Attachments.map((file) => (
                <img
                  src={
                    file.AID
                      ? URL.createObjectURL(uploadedFile)
                      : baseUrl.baseUrl + file.upload_path
                  }
                  crossOrigin="anonymous"
                  title="Attachemnt"
                  className="w-[300px] h-[200px] rounded-xl"
                />
              ))}
          </div>
        ) : null}

        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit}
          endIcon={<SaveIcon />}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Post;
