import { Delete, Edit } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAbout,
  getAllAbout
} from "../../redux/about/aboutSlice";
import DOMPurify from "dompurify";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import SearchIcon from "@mui/icons-material/Search";
import baseUrl from "../../Util/baseURL";

const AboutList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const aboutList = useSelector(getAllAbout);

  useEffect(() => {
    dispatch(getAbout());
  }, []);

  const handleEdit = (e, id) => {
    e.preventDefault();
    navigate(`/add-about/${id}`, { state: { id: id } });
  };
  // const handleDelete = (e, id) => {
  //     e.preventDefault();
  //     dispatch(deletePostById(id)).then(res => {
  //           if (res.error) {
  //             toast.error("Error deleting post " + res.error.message);
  //           } else {
  //             dispatch(getAbout());
  //             toast.success("Deleted successfully!");
  //           }
  //     });
  // }
  const [searchText, setSearchText] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      aboutList && aboutList.length > 0 && aboutList.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(aboutList.length / itemsPerPage));
  }, [aboutList]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % aboutList.length;
    const endOffset = newOffset + itemsPerPage;

    setCurrentItems(
      aboutList && aboutList.length > 0 && aboutList.slice(newOffset, endOffset)
    );

    setItemOffset(newOffset);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);

    const temp = aboutList.filter((item) => {
      if (!e.target || !e.target.value) {
        // dispatch(getAbout());
        setCurrentItems(aboutList);
        // return false;
      }

      return (
        item.title
          .toLowerCase()
          .toString()
          .search(e.target.value.toLowerCase()) !== -1 ||
        item.post_type
          .toLowerCase()
          .toString()
          .search(e.target.value.toLowerCase()) !== -1
      );
    });
    setCurrentItems(temp);
  };
  return (
    <div>
      <div>
        <div className="hidden md:flex bg-white w-full rounded-md flex items-center justify-start py-2 px-2 shadow-md">
          <div className=" text-skin-iconText">
            <SearchIcon />
          </div>
          <div className="w-full">
            <input
              className="w-full px-2 py-1 text-slate-700 outline-none border-none"
              value={searchText}
              onChange={(e) => handleSearch(e)}
              placeholder="Search..."
            />
          </div>
        </div>
      </div>

      <table style={{ width: "100%", marginTop: "15px" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid lightgray" }}>Profile Header Title</th>
            <th style={{ border: "1px solid lightgray" }}>Profile Header Value</th>
            <th style={{ border: "1px solid lightgray" }}>Title Animation Header</th>
            <th style={{ border: "1px solid lightgray" }}>Title Animation Value</th>
            <th style={{ border: "1px solid lightgray" }}>Description</th>
            <th style={{ border: "1px solid lightgray" }}>Image</th>
            <th style={{ border: "1px solid lightgray" }}>Additional Field 1</th>
            {/* <th style={{ border: "1px solid lightgray" }}>Additional Field 2</th>
            <th style={{ border: "1px solid lightgray" }}>Additional Field 3</th>
            <th style={{ border: "1px solid lightgray" }}>Additional Field 4</th>
            <th style={{ border: "1px solid lightgray" }}>Additional Field 5</th> */}
            <th style={{ border: "1px solid lightgray" }}>Action</th>
          </tr>
        </thead>
        <tbody>
        {currentItems &&
            currentItems.length > 0 &&
            currentItems.map((x, i) => (
              <tr key={i}>
                <td
                  style={{
                    width: "200px",
                    padding: "5px",
                    border: "1px solid lightgray",
                  }}
                >
                  {x.profile_header_title}
                </td>
                <td
                  style={{
                    width: "200px",
                    padding: "5px",
                    border: "1px solid lightgray",
                  }}
                >
                  {x.profile_header_value}
                </td>
                <td
                  style={{
                    width: "200px",
                    padding: "5px",
                    border: "1px solid lightgray",
                  }}
                >
                  {x.title_animation_value}
                </td>
                <td
                  style={{
                    width: "200px",
                    padding: "5px",
                    border: "1px solid lightgray",
                  }}
                >
                  {x.title_animation_header}
                </td>
                <td>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(x.description.substring(0, x.description.indexOf("</p>") + 4))}}></p>
                </td>
                {/* <td
                  style={{
                    width: "200px",
                    padding: "5px",
                    border: "1px solid lightgray",
                  }}
                >
                  {x.description}
                </td> */}
                <td
                  style={{
                    width: "200px",
                    padding: "5px",
                    border: "1px solid lightgray",
                  }}
                >
                  <img
                    loading="lazy"
                    crossOrigin="anonymous"
                    src={baseUrl.baseUrl + x.img.split("/webapi.aamuyeed.com").pop()}
                  ></img>
                </td>
                <td>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(x.additional_field_1.substring(0, x.additional_field_1.indexOf("</p>") + 4))}}></p>
                </td>
                {/* <td>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(x.additional_field_2.substring(0, x.additional_field_2.indexOf("</p>") + 4))}}></p>
                </td>
                <td>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(x.additional_field_3.substring(0, x.additional_field_3.indexOf("</p>") + 4))}}></p>
                </td>
                <td>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(x.additional_field_4.substring(0, x.additional_field_4.indexOf("</p>") + 4))}}></p>
                </td>
                <td>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(x.additional_field_5.substring(0, x.additional_field_5.indexOf("</p>") + 4))}}></p>
                </td> */}
                <td
                  style={{
                    width: "100px",
                    padding: "5px",
                    border: "1px solid lightgray",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    color="primary"
                    onClick={(e) => handleEdit(e, x.id)}
                  >
                    <Edit />
                  </IconButton>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="w-full flex items-center gap-3 py-4 justify-end"
        activeClassName="bg-purple-100 text-purple-800 rounded-full px-2 py-1 h-[30px] w-[30px] flex items-center justify-center"
        pageLinkClassName="border px-4 py-2 rounded-[50%] hover:bg-slate-200"
      /> */}
    </div>
  );
};

export default AboutList;
