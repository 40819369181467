import baseUrl from "./baseURL";

export class UrlConfig {
  // constructor() {
  //   if(process.env.NODE_ENV !== 'production')
  //       this.baseUrl = baseUrl.baseUrlDev && baseUrl.baseUrlDev;
  //   else
  //       this.baseUrl = baseUrl.baseUrlLive && baseUrl.baseUrlLive;
  // }

  // getBaseUrl = () => {
  //   return this.baseUrl;
  // };
  constructor(module) {
    this.baseUrl = baseUrl && baseUrl.baseUrl;
  }

  getBaseUrl = () => {
    return this.baseUrl;
  };
}
