import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../helper";
import {
  getAboutC,
  getAboutById,
  saveAbout,
  setAbout,
} from "../../redux/about/aboutSlice";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import Editor from "../../components/editor/Editor";
import _ from "../../helper/lodash";
import DropzoneFileUploader from "../../helper/DropzoneFileUploader";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import baseUrl from "../../Util/baseURL";


const KeyCodes = {
  comma: 188,
  enter: 13,
};
const About = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();
  const about = useSelector(getAboutC);

  const { form, handleChange, setForm } = useForm(about);
  const [viewDbPreviewImg, setViewDbPreviewImg] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  

  useEffect(() => {
    if (id === "new") {
      dispatch(setAbout());
    } else {
      dispatch(getAboutById(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (about !== form) {
      setForm(form);
    }
  }, [form, handleChange, setForm]);
  useEffect(() => {
    setForm(about);
  }, [about]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const upForm = {
      ...form,
    };
    dispatch(saveAbout(upForm)).then((res) => {
      if (id != "new" && !res.error) {
        navigate("/about");
      }
      if (res.error) {
        dispatch(setAbout());
        toast.error("Error saving about " + res.error.message);
      } else {
        console.log(upForm);
        dispatch(setAbout());
        navigate("/about");
        toast.success("About saved successfully");
      }
    });
  };

  const setContent = (content) => {
    setForm(
      _.set(
        { ...form },
        "content",
        // DOMPurify.sanitize(content, { ALLOW_SCRIPTS: false })
        content
      )
    );
  };

  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    setUploadedFile(files[0]);
    setViewDbPreviewImg(false);
    let tmpAtc =
      form && form.Attachments && form.Attachments.length > 0
        ? [...form.Attachments]
        : [];

    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin
        if (tmpAtc.length > 0) {
          tmpAtc[0] = {
            AID: Math.random(),
            img: `data:${files[index].type};base64,${btoa(bin)}`,
            type: files[index].type,
            filename: files[index].name,
          };
        } else {
          tmpAtc.push({
            AID: Math.random(),
            img: `data:${files[index].type};base64,${btoa(bin)}`,
            type: files[index].type,
            filename: files[index].name,
          });
        }

        setForm(_.set({ ...form }, `Attachments`, tmpAtc));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <TextField
          required
          id="outlined-required"
          value={form.profile_header_title}
          onChange={handleChange}
          name="profile_header_title"
          label="Profile Header Title"
          className="flex-1 w-full"
        />
      </div>
      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <TextField
          id="outlined-required"
          label="Profile Header Value"
          value={form.profile_header_value}
          name="profile_header_value"
          onChange={handleChange}
          className="flex-1 w-full"
        />
      </div>

      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <TextField
          id="outlined-required"
          label="Animation Title"
          value={form.title_animation_value}
          name="title_animation_value"
          onChange={handleChange}
          className="flex-1 w-full"
        />
      </div>

      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <TextField
          id="outlined-required"
          label="Animation Header"
          value={form.title_animation_header}
          name="title_animation_header"
          onChange={handleChange}
          className="flex-1 w-full"
        />
      </div>

      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <Editor
          label="Description/Content"
          content={form.description}
          setContent={setContent}
        />
      </div>

      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <Editor
          label="Additional Field 1"
          content={form.additional_field_1}
          setContent={setContent}
        />
      </div>

      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <Editor
          label="Additional Field 2"
          content={form.additional_field_2}
          setContent={setContent}
        />
      </div>

      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <Editor
          label="Additional Field 3"
          content={form.additional_field_3}
          setContent={setContent}
        />
      </div>

      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <Editor
          label="Additional Field 4"
          content={form.additional_field_4}
          setContent={setContent}
        />
      </div>

      <div className="flex-1 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <Editor
          label="Additional Field 5"
          content={form.additional_field_5}
          setContent={setContent}
        />
      </div>

      <div className="flex items-center gap-4 flex-col">
        <DropzoneFileUploader
          acceptedFileTypes={["image/jpg", "image/jpeg", "image/png"]}
          handleAddAttachment={handleAddAttachment}
        />

        <div className="py-8">
          {form &&
            form.Attachments &&
            form.Attachments.length > 0 &&
            form.Attachments.map((file) => (
              <div className="flex flex-1 items-center">
                <p>{file.filename}</p>
              </div>
            ))}
        </div>
        {id === "new" ? (
          <div>
            {form &&
              form.Attachments &&
              form.Attachments.length > 0 &&
              form.Attachments.map((file) => (
                <img
                  src={
                    file.AID
                      ? URL.createObjectURL(uploadedFile)
                      : baseUrl.baseUrl + file.upload_path
                  }
                  crossOrigin="anonymous"
                  title="Attachemnt"
                  className="w-[300px] h-[200px] rounded-xl"
                />
              ))}
          </div>
        ) : (
          <div>
            {viewDbPreviewImg ? (
              <img
                crossOrigin="anonymous"
                src={
                  form &&
                  form.img &&
                  baseUrl.baseUrl + form.img.split("/webapi.aamuyeed.com").pop()
                }
                alt="Image"
                className="w-[300px] h-[200px] rounded-xl"
              />
            ) : (
              <div>
                {form &&
                  form.Attachments &&
                  form.Attachments.length > 0 &&
                  form.Attachments.map((file) => (
                    <img
                      src={
                        file.AID
                          ? URL.createObjectURL(uploadedFile)
                          : baseUrl.baseUrl + file.upload_path
                      }
                      crossOrigin="anonymous"
                      title="Attachemnt"
                      className="w-[300px] h-[200px] rounded-xl"
                    />
                  ))}
              </div>
            )}
          </div>
        )}

        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit}
          endIcon={<SaveIcon />}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default About;
