import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Icon, Menu, MenuItem } from "@mui/material";
import React from "react";
import LineColumnAreaChart from "../../components/charts/LineColumnAreaChart";
import { recentTransactions } from "../../dummyData/mock";
import ProductFeedbackList from "./ProductFeedbackList";
import RecentOrders from "./RecentOrders";

import Timeline from "./Timeline";

const Dashboard = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      className={
        "min-h-screen bg-skin-base p-4 shadow-xl rounded-2xl py-10 dark:text-slate-100 dark:bg-slate-600"
      }
    >
      <h1>Welcome to dashboard</h1>
    </div>
  );
};

export default Dashboard;
