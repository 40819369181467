import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { userLogin } from '../../redux/user/userSlice';

const Login = () => {
    const dispatch = useDispatch();
    const [userName, setUserName]= useState('')
    const [password, setPassword]= useState('')

    const handleSubmit = e =>{
        e.preventDefault();
        dispatch(userLogin({userName: userName, password: password}))
    }
  return (
    <div className="min-h-screen w-full flex items-center justify-center">
        <div className="px-8 py-12 w-[400px] shadow-md shadow-purple-800/40">
            <h2 className="py-6 text-purple-700 font-semibold text-xl flex items-center justify-center">Login</h2>
            <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-2 w-full">
                    <label className="text-slate-600 text-sm">User Name:</label>
                    <input type="text" value={userName} onChange={e=>setUserName(e.target.value)} placeholder="User Name" className="outline-none bg-slate-100 shadow-md px-4 py-2 text-" />
                </div>
                <div className="my-4 flex flex-col gap-2 w-full">
                    <label className="text-slate-600 text-sm">Password:</label>
                    <input type="password" value={password} onChange={e=>setPassword(e.target.value)}  placeholder="Password" className="outline-none bg-slate-100 shadow-md px-4 py-2 text-" />
                </div>
                <button className="hover:bg-purple-500 hover:bg-purple-600 hover:text-white border-2  border-purple-700 outline-none hover:border-solid hover:border-purple-500 bg-white text-purple-700 w-full font-semibold py-3" onClick={e=> handleSubmit(e)}>Login</button>
            </div>
        </div>
    </div>
  )
}

export default Login